import React, { Component } from "react"
import SEO from '../components/seo'
import Layout from '../components/layout'

class Contact extends Component {
  render() {
    return (
      <>
        <Layout>
          <SEO title="Contact"/>
          
        </Layout>
      </>
    )
  }
}

export default Contact;